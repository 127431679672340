import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RenderString from '../RenderString';
import { convertObjectToArray } from '../../Helpers/Functions';

import styles from './AssessmentItem.module.scss';
import Button from '../Button';

const AssessmentItem = ({ questions, type, answers, handleSubmitAnswers }) => {
  const navigate = useNavigate();
  const initialValues = useMemo(() => {
    return answers.reduce((acc, answer) => {
      acc[answer.assessmentItemId] = answer.answer;
      return acc;
    }, {});
  }, [answers]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values, { setSubmitting }) => {
        handleSubmitAnswers(convertObjectToArray(values), () => {
          setSubmitting(false);
          navigate('/tools');
        });
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className={`${styles.assessmentItem} ${styles.assessment}`}>
              {questions
                .sort((a, b) => a.priority - b.priority)
                .map((question, i) => {
                  const marks = [
                    {
                      value: 1,
                      label: question.descLow
                    },
                    {
                      value: 10,
                      label: question.descHigh
                    }
                  ];
                  const name = question._id;

                  return (
                    <div className={styles.assessment_order} key={question._id}>
                      <div className={styles.question}>
                        <RenderString content={question.title} />
                      </div>
                      {question.type === 'rating' && (
                        <>
                          <Slider
                            valueLabelDisplay="auto"
                            sx={{
                              width: '70%',
                              marginLeft: '2rem'
                            }}
                            step={1}
                            min={1}
                            max={10}
                            marks={marks}
                            id={name}
                            name={name}
                            value={values[name] || 1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />

                          {question.scaleDesc !== '' && (
                            <Typography style={{ fontWeight: '400', fontSize: '15px' }}>
                              {question.scaleDesc}
                            </Typography>
                          )}
                        </>
                      )}

                      {question.type === 'radio' && (
                        <FormControl sx={{ marginLeft: '2rem' }}>
                          <RadioGroup
                            aria-labelledby={`assessment-radio-buttons-group-label-${i}`}
                            id={name}
                            name={name}
                            value={values[name] || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            {question?.options &&
                              question.options.map((option, index) => (
                                <FormControlLabel
                                  control={<Radio />}
                                  label={option}
                                  value={option}
                                  key={`option-${index}`}
                                />
                              ))}
                          </RadioGroup>
                        </FormControl>
                      )}

                      {question.type === 'checkbox' && (
                        <FormControl
                          sx={{ marginLeft: '2rem' }}
                          component="fieldset"
                          variant="standard"
                          id={name}
                          name={name}
                          value={values[name] || []}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {question?.options &&
                            question.options.map((option, index) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={name}
                                    checked={!!values[name]?.find((item) => item === option)}
                                    defaultChecked={false}
                                  />
                                }
                                label={option}
                                value={option}
                                key={`option-${index}`}
                              />
                            ))}
                        </FormControl>
                      )}

                      {question.type === 'input' && (
                        <TextField
                          margin="normal"
                          fullWidth
                          placeholder="Type here..."
                          id={name}
                          name={name}
                          value={values[name]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}

                      {question.type === 'textarea' && (
                        <TextField
                          margin="normal"
                          fullWidth
                          multiline
                          rows={4}
                          placeholder="Type here..."
                          id={name}
                          name={name}
                          value={values[name]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
            <div className={`${styles.assessmentItem_row} ${styles.alignEnd}`}>
              <Button disabled={isSubmitting} type="submit">
                {type === 'taken' ? 'Update' : 'Submit'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssessmentItem;
